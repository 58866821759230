import React from "react";
import "./testimonials.css";
import sydney from "../../assets/sydney.jpeg";
import rob from "../../assets/rob.jpeg";
import person3 from "../../assets/person3.jpg";
import person4 from "../../assets/person4.jpg";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";

const data = [
  {
    avatar: sydney,
    name: "Sydney Arin Go",
    review:
      "Ryan is an amazingly detailed person.  In the three months that we worked together, Ryan created content that generated signups and helped Acadium grow. During his time as an ambassador, he completed 95% of the tasks assigned (the average for his cohort was ~70%). He answered Quora questions, posted on reddit, and wrote a handful of blog posts. He's a fast learner and a great team player. Anyone would be lucky to have him on their team.",
  },
  {
    avatar: rob,
    name: "Robert C Robertson",
    review:
      "“Ryan has a wonderful customer focused attitude and the outstanding people skills that are less common in the tech industry. Outstanding recommendation from me”",
  },

  /*

  {
    avatar: person3,
    name: "Ben",
    review:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi blanditiis doloremque, mollitia in consequuntur quos! Voluptate officiis odio illo saepe.",
  },
  {
    avatar: person4,
    name: "Brenda",
    review:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi blanditiis doloremque, mollitia in consequuntur quos! Voluptate officiis odio illo saepe.",
  },

  */
];

const Testimonial = () => {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper
        className="container testimonials__container"
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide ket={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};

export default Testimonial;
