import React from "react";
import CTA from "./CTA";
import me3 from "../../assets/me3.png";
import HeaderSocials from "./HeaderSocials";
import "./header.css";

const Head = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I'm</h5>
        <h1>Ryan Neveu</h1>
        <h5 className="text-light">Junior developer</h5>
        <CTA />
        <HeaderSocials />

        <div className="me">
          <img src={me3} alt="profile" />
        </div>
        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Head;
