import React from "react";
import "./about.css";
import Me4 from "../../assets/Me4.jpg";
import { FaAward } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know Me</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={Me4} alt="About image" />
          </div>
        </div>

        <div className="about__content" href="">
          <div className="about__cards">
            <a href="#experience">
              <article className="about__card">
                <FaAward className="about__icon" />
                <h5>Experience</h5>
              </article>
            </a>
            <a href="#clients">
              <article className="about__card">
                <FiUsers className="about__icon" />
                <h5>Clients</h5>
              </article>
            </a>
            <a href="#portfolio">
              <article className="about__card" id="projects">
                <VscFolderLibrary className="about__icon" />
                <h5>Projects</h5>
              </article>
            </a>
          </div>
          <p>
            Hi, I'm Ryan. I am a keen web developer at the start of my journey.
            I have experience running teams and large scale projects.
          </p>
          <a href="contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
