import React from "react";
import Head from "./components/header/Header";
import Nav from "./components/nav/Nav";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonial from "./components/testimonials/Testimonial";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Clients from "./components/clients/Clients";

{
  /* Youtube tutorial for this project found at this URL - https://www.youtube.com/watch?v=G-Cr00UYokU */
}

const App = () => {
  return (
    <>
      <Head />
      <Nav />
      <About />
      <Experience />
      <Clients />
      <Testimonial />
      <Portfolio />
      <Contact />
      <Footer />
    </>
  );
};

export default App;
