import React from "react";
import scanner_image from "../../assets/scanner_image.jpg";
import material_calc_image from "../../assets/material_calc_image.jpg";
import "./portfolio.css";

const data = [
  {
    id: 1,
    image: scanner_image,
    title: "QR code scanner",
    github: "https://qr-code-scanner.pages.dev/",
  },
  {
    id: 2,
    image: material_calc_image,
    title: "Material Calculator",
    github: "https://github.com",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container">
        {data.map(({ id, image, title, github }) => {
          return (
            <article key={id} className="portfolio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn btn-primary">
                  Live Demo
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
