import React from "react";
import rob from "../../assets/rob.jpeg";
import ashley from "../../assets/ashley.jpeg";
import sydney from "../../assets/sydney.jpeg";
import { BsPatchCheckFill } from "react-icons/bs";
import blockchain from "../../assets/blockchain.jpg";
import { useState } from "react";
import "./clients.css";

const data = [
  {
    id: 1,
    image: rob,
    title: "Robert C Robertson",
    subtitle: "Unboxed Digital CEO",
    keypoint1: "Built multiple websites using Oxygen builder",
    keypoint2: "Buld website",
    body: "I was reasearching how to get in to the web development industry, and found the Auckland Wordpress Meetup group. I reached out to the group and Rob got back to me, he was running the group at the time. Rob told me that he had started his own web development business and was looking for an intern, I jumped at the oppurtunity! I completed my internship with Rob and continued with him for a while doing paid work on sites such as and",
  },
  {
    id: 2,
    image: sydney,
    title: "Sydney Arin Go",
    subtitle: "Acadium Growth Marketer",
    keypoint1: "Completed Acadium apprenticeship with 5 stars",
    keypoint2: "Became an Acadium Ambassador",
    body: "Whilst researching how I could break into the web development industry I cam across a company called Acadium. Acadium allows people to undertake online apprenticeships in which applicants get to gain real world experience as digital marketers and junior SEO specialists. I completed an Acadium apprenticeship with 5 stars. Acadium then contacted me about becoming an ambassador for them. I completed the ambassador program.",
  },

  /* 
  {
    id: 3,
    image: ashley,
    title: "Ashley Baldry",
    subtitle: "High Performance Profiling CEO",
    keypoint1: "Built first iteration of website",
    keypoint2: "Commuicated with designer and client for progress reports",
    body: "I built Ashley's website",
  },

  */
];

const Clients = () => {
  const [showMore, setShowMore] = useState();
  const showMoreClick = (id) => {
    setShowMore(id);
  };
  const showLessClick = () => {
    setShowMore(undefined);
  };

  return (
    <section id="clients">
      <h5>Who I've worked for</h5>
      <h2>Clients</h2>
      <div className="container clients__container">
        {data.map(
          ({ id, image, title, body, subtitle, keypoint1, keypoint2 }) => {
            return (
              <div>
                <article key={id} className="clients__item">
                  <div className="clients__item-image">
                    <img src={image} alt={title} />
                  </div>
                  <div className="clients__info">
                    <h3>{title}</h3>
                    <h4>{subtitle}</h4>
                    <h5>
                      <BsPatchCheckFill id="tick" />
                      {keypoint1}
                    </h5>

                    <h5>
                      <BsPatchCheckFill id="tick" />
                      {keypoint2}
                    </h5>
                  </div>

                  <div>
                    {showMore !== id && (
                      <small
                        className="clients__showmoreless"
                        onClick={() => showMoreClick(id)}
                      >
                        See more
                      </small>
                    )}
                    {showMore === id && (
                      <div>
                        <small onClick={showLessClick}>See less</small>
                        <p>{body}</p>
                      </div>
                    )}
                  </div>
                </article>
                <div></div>
              </div>
            );
          }
        )}
      </div>
    </section>
  );
};

export default Clients;
